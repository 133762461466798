<template>
  <BaseForm>
    <CTabs
      add-nav-wrapper-classes="settings-horizontal-tabs"
      :vertical="false"
      variant="tabs"
    >
      <CTab v-for="(tab, index) in tabs" :key="index">
        <template #title> {{ tab.name }} </template>
        <component :is="tab.component" />
      </CTab>
    </CTabs>
  </BaseForm>
</template>

<script>
import BaseForm from '../BaseForm.vue'
import Contacts from './Contacts.vue'
import Emails from './Emails.vue'
import SocialMedia from './SocialMedia.vue'
import ThirdPartySoftware from './ThirdPartySoftware.vue'
import Environment from './Environment.vue'

const tabs = [
  { name: 'Contatos', component: Contacts },
  { name: 'Emails', component: Emails },
  { name: 'Redes Sociais e Links', component: SocialMedia },
  { name: 'Aplicativos de Terceiros', component: ThirdPartySoftware },
  { name: 'Ambiente', component: Environment }
]

export default {
  components: { BaseForm },
  data: () => ({ tabs })
}
</script>
