<template>
  <CRow class="mt-4 mb-4">
    <CCol col="12">
      <h5 class="mb-4">
        <b>Redes sociais</b>
      </h5>
    </CCol>

    <CCol col="12" lg="6" v-for="item in networks" :key="item.field">
      <CInput
        :value="form[item.field]"
        :placeholder="item.placeholder"
        :description="item.description"
        @input="(val) => input({ [item.field]: val })"
        type="text"
      >
        <template #prepend>
          <CButton :class="item.btnClass">
            <CIcon :name="item.icon" height="14" />
          </CButton>
        </template>
      </CInput>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  methods: { ...mapActions('settings', ['input']) },
  computed: { ...mapGetters('settings', ['form']) },
  data: () => ({
    networks: [
      {
        field: 'twitter',
        btnClass: 'btn-twitter',
        icon: 'cib-twitter',
        placeholder: 'Digite o link para o Twitter'
      },
      {
        field: 'facebook',
        btnClass: 'btn-facebook',
        icon: 'cib-facebook',
        placeholder: 'Digite o link para o Facebook'
      },
      {
        field: 'instagram',
        btnClass: 'btn-instagram',
        icon: 'cib-instagram',
        placeholder: 'Digite o link para o Instagram'
      },
      {
        field: 'youtube',
        btnClass: 'btn-youtube',
        icon: 'cib-youtube',
        placeholder: 'Digite o link para o Youtube'
      },
      {
        field: 'linkedin',
        btnClass: 'btn-linkedin',
        icon: 'cib-linkedin',
        placeholder: 'Digite o link para o LinkedIn'
      }
    ]
  })
}
</script>
