<template>
  <div>
    <CRow class="mt-4">
      <CCol col="12">
        <h5>
          <b>Contatos</b>
        </h5>
      </CCol>
    </CRow>
    <CRow class="mt-4 mb-4">
      <CCol col="12" lg="6">
        <div role="group" class="form-group">
          <label for="phone">Telefone</label>
          <input
            id="phone"
            v-model="form.phone"
            v-mask="phoneMask"
            type="tel"
            placeholder="(00) 00000-0000"
            class="form-control"
          />
        </div>
      </CCol>

      <CCol col="12" lg="6">
        <div role="group" class="form-group">
          <label for="cellphone">Celular</label>
          <input
            id="cellphone"
            v-model="form.cellphone"
            v-mask="cellphoneMask"
            type="tel"
            placeholder="(00) 00000-0000"
            class="form-control"
          />
        </div>
      </CCol>

      <CCol col="12" lg="6">
        <div role="group" class="form-group">
          <label for="whatsapp">Whatsapp</label>
          <input
            id="whatsapp"
            v-model="form.whatsapp"
            v-mask="whatsappMask"
            type="tel"
            placeholder="(00) 00000-0000"
            class="form-control"
          />
        </div>
      </CCol>

      <CCol col="12" lg="6">
        <CInput
          v-model="form.email"
          label="E-mail"
          placeholder=""
          :description="`Email que será exibido no rodapé do site`"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { phoneMask } from '@/utils'
import labelIconMixin from '@/mixin/label-icon'
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('settings', ['input']),
    setAnalitycsStatus(scripts) {
      this.input({ scripts })
    }
  },
  computed: {
    ...mapGetters('settings', ['form']),
    phoneMask() {
      return phoneMask(this.form.phone)
    },

    cellphoneMask() {
      return phoneMask(this.form.cellphone)
    },

    whatsappMask() {
      return phoneMask(this.form.whatsapp)
    }
  },
  mixins: [labelIconMixin]
}
</script>
