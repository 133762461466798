<template>
  <div>
    <CRow class="mt-4">
      <CCol col="12">
        <h5>
          <b>Aplicativos de Terceiros</b>
        </h5>
      </CCol>
    </CRow>
    <CRow class="mt-4 mb-4">
      <CCol col="12" sm="6">
        <div class="d-flex align-items-center">
          <label for="scripts" class="mb-0 mr-1">Ativar scripts</label>
          <CSwitch
            id="scripts"
            class="mx-1"
            color="success"
            variant="3d"
            v-bind="labelIcon"
            :checked="form.scripts === '1' ? true : false"
            @update:checked="setAnalitycsStatus($event)"
          />
        </div>
      </CCol>
    </CRow>
    <CRow class="mt-4 mb-4">
      <CCol col="12" sm="6">
        <CInput
          :value="form.google_analytics_id"
          @input="(e) => input({ google_analytics_id: e })"
          label="Google Analytics"
          placeholder="Ex: UA-10000000-00"
          description="Insira o código gerado pelo Google Analytics."
        />
      </CCol>

      <CCol col="12" sm="6">
        <CInput
          :value="form.google_site_verification"
          @input="(e) => input({ google_site_verification: e })"
          label="Google Site Verification"
          placeholder="Ex: DYHYmfhZQ41R0uRhPPSVw3kzfWyC36eM6XJqaLuYQkg"
          description="Insira a key de verificação gerada para indexação do domínio."
        />
      </CCol>

      <CCol col="12" sm="6">
        <CInput
          :value="form.rd_station"
          @input="(e) => input({ rd_station: e })"
          label="RD Station"
          placeholder="Ex: xFImZnziCZgGGNSzCvLTSxjBVJOrmAsCYUtZ"
        >
          <template #description>
            <small class="form-text text-muted w-100">
              Insira a chave de API de integração. Saiba com gerar
              <a
                href="https://ajuda.rdstation.com.br/hc/pt-br/articles/360041572592-Chaves-de-API"
                target="_blank"
              >
                clicando aqui </a
              >.
            </small>
          </template>
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import labelIconMixin from '@/mixin/label-icon'
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('settings', ['input']),
    setAnalitycsStatus(scripts) {
      this.input({ scripts })
    }
  },
  computed: { ...mapGetters('settings', ['form']) },
  mixins: [labelIconMixin]
}
</script>
