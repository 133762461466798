<template>
  <div>
    <CRow class="mt-4">
      <CCol col="12">
        <h5>
          <b>Emails</b>
        </h5>
      </CCol>
    </CRow>
    <CRow class="mt-4 mb-4">
      <CCol col="12">
        <CAlert color="info" class="ml-1">
          Informe os e-mails separados por ;. Caso nenhum e-mail seja informado
          todos os usuários administradores receberão os e-mails
        </CAlert>
      </CCol>

      <CCol col="12" sm="6">
        <CInput
          v-model="form.responsible_for_contacts"
          label="Responsável pelos contatos do site"
          placeholder=""
          :description="`E-mail que receberá as notificações dos contatos do site`"
        />
      </CCol>

      <CCol col="12" sm="6">
        <CInput
          v-model="form.responsible_for_comments"
          label="Responsável pelos comentários"
          placeholder=""
          :description="`E-mail que receberá as notificações dos comentários`"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import labelIconMixin from '@/mixin/label-icon'
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('settings', ['input']),
    setAnalitycsStatus(scripts) {
      this.input({ scripts })
    }
  },
  computed: { ...mapGetters('settings', ['form']) },
  mixins: [labelIconMixin]
}
</script>
